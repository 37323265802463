.container-intro-top{
  height: 85vh;
  align-items: center;
  align-self: center;
  align-content: center;
  .container-intro-top-inner{
    margin-top: 15vh;
  }
}

@keyframes pulse {
  0% {
      opacity: 0.3;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0.3;
  }
}
@keyframes scroll {
  0% {
      transform: translateY(0.5vh);
  }

  50% {
    transform: translateY(0vh);
  }

  100% {
    transform: translateY(0.5vh);
  }
}

.container-intro-bottom{
  height: 10vh;
  align-items: center;
  align-self: center;
  align-content: center;
  svg{
    height: 3vh;
    width: auto;
    animation: scroll 2.5s ease-out;
    animation-iteration-count: infinite;
    #top{
      animation: pulse 2.5s ease-out;
      animation-iteration-count: infinite;
      animation-delay: 0.3s;
      opacity: 1;
    }
    #bottom{
      animation: pulse 2.5s ease-out;
      animation-iteration-count: infinite;
      opacity: 1;
    }
  }
}