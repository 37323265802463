@import '../../index.scss';

.w-100vw {
    width: 100vw;
}

.container-cv {
    height: 250vh;
    max-width: 99vw;
    overflow: hidden;
  //  border: 1px solid red;

    .container-cv-inner {
        top: 0;
    }
}

#progress {
    position: fixed;
    top: 20px;
    left: 20px;
    transform: rotate(-90deg);
}

circle {
    stroke-dashoffset: 0;
    stroke-width: 15%;
    fill: none;
}

#progress .indicator {
    stroke: $primary-color;
}

.container-step {
    position: absolute;

    #cv {
        position: absolute;
        z-index: 10;
    }

    #ufp {
        top: 4vh;
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }

    #hcpro {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }
    #invisual {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }

    .container-left-step {
        display: grid;
        align-content: center;
        height: 100vh;
    }

    .container-get-in-touch{
        svg{
            height: 100%;
            width: 100%;
        }
    }
    .container-step5-inner{
        height: 100vh;
        display: grid;
        align-items: center;
    }

    @media (max-width: 990px){
        .container-left-step {
            height: 50vh;
        }
    }
}