$primary-color: #f29559;
$second-color: #4f5d75;
$grey: #bfc0c0;
$white: #ffffff;
$dark: #2d3142;

body {
  margin: 0;
  font-family: "Inter", sans-serif !important;
  background-color: $dark !important;
  color: $white !important;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 3vw;
  background-image: radial-gradient(rgba(0, 0, 0, 0) 1px,
      $dark 1px);
  background-size: 4px 4px;
  backdrop-filter: blur(3px);
}

footer::before {
  display: block;
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: $dark;
  opacity: 0.2;
}

footer a {
  text-decoration: none;
  color: $grey;
}

code {
  font-weight: 400;
  font-style: normal;
}

article {
  max-width: 500px;
  padding: 20px;
  margin: 0 auto;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0.5vh;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  background: $primary-color !important;
  transform-origin: 0%;
}

.overflow-hidden {
  overflow: hidden;
}

@keyframes pulse {
  0% {
    scale: 1;
  }

  50% {
    scale: 1.01;
  }

  100% {
    scale: 1;
  }
}


.container-email {
  animation-delay: 0s;
}

.container-phone {
  animation-delay: 0.5s;
}

.container-message {
  animation-delay: 1s;
}

.icon-pulse {
  animation: pulse 1.5s ease-out;
  animation-iteration-count: infinite;
}